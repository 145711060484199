(function ($) {
  var $keys = saphaliWoocommerceLiteSettings?.keys || [];
  var $skeys = saphaliWoocommerceLiteSettings?.skeys || [];
  function corect_payment_method_filds() {
    var selected_p_method = $('input[name="payment_method"]:checked').val();
    $.each($keys, function (i, e) {
      e = Object.keys(e).map(function (v) {
        return e[v];
      });
      if ($.inArray(selected_p_method, e) >= 0) {
        if (
          !(
            $("#billing_platelshik_is_grpl").is(":checked") &&
            (i == "billing_gruzopoluch" || i == "billing_gruzopoluch_okpo")
          )
        )
          $("#" + i + "_field").show("slow");
      } else {
        $("#" + i + "_field").hide("slow");
      }
    });
  }
  function corect_shipping_method_filds() {
    var selected_s_method =
      typeof $(
        'input.shipping_method:checked, input.shipping_method[type="hidden"], select.shipping_method'
      ).val() != "undefined"
        ? $(
            'input.shipping_method:checked, input.shipping_method[type="hidden"], select.shipping_method'
          )
            .val()
            .split(":")[0]
        : "";
    $.each($skeys, function (i, e) {
      e = Object.keys(e).map(function (v) {
        return e[v];
      });
      if (
        $.inArray(selected_s_method, e) >= 0 ||
        e.filter(function (v) {
          return selected_s_method.indexOf(v) === 0;
        }).length > 0
      ) {
        $("#" + i + "_field").show("slow");
      } else {
        $("#" + i + "_field").hide("slow");
      }
    });
  }
  $("body").on("click", 'input[name="payment_method"]', function () {
    corect_payment_method_filds();
  });
  $("body").on("payment_method_selected", function () {
    corect_payment_method_filds();
  });
  $("body").on("click", "input.shipping_method", function () {
    corect_shipping_method_filds();
  });
  $("body").on("change", "select.shipping_method", function () {
    corect_shipping_method_filds();
  });
  $("body").on("updated_checkout", function () {
    corect_payment_method_filds();
    corect_shipping_method_filds();
  });
})(jQuery);
